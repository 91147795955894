import {faCaretRight} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Blog from '../components/Blog/Blog';
import Button from '../components/Button/Button';
import Image from '../components/Image/Image';

import articlePdf from '../assets/sklepy.svg';

const Post1 = () => {
    const content = {
        img: 'post-title-monnari.png',
        desc: 'Interview with Director of Operations at Monnari Trade, Piotr Olczyk.',
        title: '„Managing a field team is not an easy job”',
        excerpt:
            'On how to make this task simpler and where exactly lies the challenge, we talk to the Director of Operations at Monnari Trade, Piotr Olczyk.',
    };

    const {img, desc, title, excerpt} = content;
    return (
        <Blog title={title} img={img} desc={desc} excerpt={excerpt}>
            <>
                <div className='block'>
                    <div className='col-2-1'>
                        <div>
                            <p>
                                <strong>
                                    Monnari is an organization of over 170 stores all over the country, and over a
                                    thousand salespeople running them. There are over 30 people working in the field
                                    every day, including regional managers, micro-region supervisors etc. What
                                    challenges do you meet in the day-to-day management of such a large distributed
                                    team?
                                </strong>
                            </p>
                            <p>
                                Managing a field team is not an easy job. In our day-to-day operations we focus on
                                delivering the best possible experience for our customers and we define it very broadly
                                – from the generally perceived VM, to all the sales- and sale-related activities.
                                Everyday work of a point of sale is a series of small activities. The quality of
                                execution of these small things makes the whole – an efficient operation of the point of
                                sale. Coordinating and enforcing this seemingly obvious routine is a real challenge.
                            </p>
                        </div>
                        <Image filename='monnari-post.jpg'/>
                    </div>
                </div>
                <div className='block'>
                    <p>
                        <strong>
                            Did this job get easier after deploying cHow? What are the main benefits you see from using
                            the system?
                        </strong>
                    </p>
                    <p>
                        Day-to-day challenges are still out there – it could be said they are even bigger, because we’re
                        more aware of the current situation. Thanks to introducing cHow we’ve gained the possibility to
                        track the progress of the tasks, both within the store structure and the mid-level management.
                        The Regional Managers and the Board have been given the tool they had needed to assess the
                        quality of work and time utilization. We have significantly increased the efficiency and the
                        quality of operations. The detailed quantification of the processes substantially decreased the
                        time needed to carry them out, in some cases we replaced them with new, more effective ones.
                    </p>
                </div>
                <div className='block'>
                    <p>
                        <strong>
                            Monnari has been using cHow for more than half a year now. There have been over 166 thousand
                            tasks routed through the system, and 90% of them were reported complete. How did you manage
                            to reach such high levels already in the first months of working with the system?
                        </strong>
                    </p>
                    <p>
                        cHow reports reflect a day of our work. Zalass Consulting were 100% open to our suggestions and
                        needs when adopting the system for us. Together we’ve built a task force that worked out the
                        operation model that allowed us to reach the goal. The effect of their work is the current shape
                        of the cHow solution.
                    </p>
                    <p>
                        The 90% efficiency in task execution is reach thanks to the alignment of the system to the
                        specifics of our company, the effectiveness of the tool itself and finally, the dutifulness of
                        our employees. Lack of technical issues, intuitive design, swift Help Desk, frequent updates and
                        new functionalities allow us to work with cHow with no problems nor reservations and the
                        employees don’t refrain from using the application either.
                    </p>
                </div>
                <div className='block'>
                    <p>
                        <strong>
                            The nature of the tasks routed through the platform varies greatly – you’re asking point of
                            sale employees about things related to displaying promotions, you send out VM instructions
                            and reports, collect the orders for employee clothing, signal payment terminal malfunctions,
                            keep the employee score sheets. Who’s the main recipient of the data? What divisions?
                        </strong>
                    </p>
                    <p>
                        cHow delivers the data which – I can express it with full responsibility today – is being
                        accessed as reports by the entire company. The catalogue of reports has been crafted in a way
                        that essentially cHow has become the main channel for information exchange between the store,
                        the management and the umerics.
                    </p>
                </div>
                <div className='block'>
                    <p>
                        <strong>Have the data acquired using cHow these days been available to the organization
                            before? </strong>
                    </p>
                    <p>
                        The data were surely available beforehand, but their acquisition was nowhere that quick and
                        systematic as these days. After 2-3 months of using the system we started expanding the
                        structure of reporting into new areas. As an effect – we improved our efficiency and reduces the
                        time to implement new tasks and solutions.
                    </p>
                </div>
                <div className='block'>
                    <p>
                        <strong>
                            It’s said that acquiring the data is just a part of the success. The other part is the
                            ability to use umer. How does it look like at your company? Was the appearance of large
                            quantities of input a challenge for you in terms of organizing the process of “consuming”
                            it? If so, how did you cope with it?
                        </strong>
                    </p>
                    <p>
                        The volume of data we receive each day is vast. I’ve mentioned already that cHow is being used
                        by a umer of people, responsible for the entire spectrum of processes in the company. The
                        information is getting directly to the division responsible for the particular area. Back in the
                        days it used to vary, today, the “data bank” is the foundation of operations of every manager
                        responsible for sales support.
                    </p>
                </div>
                <div className='block'>
                    <p>
                        <strong>
                            Based on your experience, please tell us what factors make the introduction of an FSM system
                            in a company justifiable? When is it worth it?
                        </strong>
                    </p>
                    <p>
                        I think there’s no way to question the validity of such a deployment. The ratio of benefits to
                        expenditures is extremely favorable. Managing a distributed structure requires tools for
                        monitoring work. This is primarily used to boost efficiency. It is also important that
                        introducing such tool greatly improves the assessment of the processes we carry out.
                    </p>
                </div>
                <div className='block'>
                    <p>
                        <strong>
                            Was the deployment itself difficult for you? Sometimes we hear it from the clients they
                            defer introducing such solutions – despite seeing a need for them – since they cannot find a
                            good moment for such a big organizational undertaking. How would you refer to these
                            objections?
                        </strong>
                    </p>
                    <p>
                        I can confirm with all the responsibility that the deployment process is not difficult at all.
                        The philosophy upon which the tool works isn’t complicated. It is important though that the
                        project that is being prepared for deployment was well thought-out – which should make the
                        deployment procedure go without a hitch.
                    </p>
                </div>
            </>
        </Blog>
    );
};

export default Post1;
